
import Company from '@/models/Company'
import ViewModel from '@/models/ViewModel'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { invoice_group_options, invoice_detail_options } from '@/pages/Invoice/options'
import {
  InvoiceTemplates,
  InvoiceDeliveryMode,
  AgencyFeeModel,
  AgencyFeeMode,
  CompanyTypes,
  IndustryOptions,
} from '@/models/interface/Common'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import countryCodes from '@/data/country_options'
import stateCodes from '@/data/state_options'
import { percentageMask, currencyMask } from '@/models/interface/Masks'
import WebMessage from '@/models/WebMessage'

@Component({
  components: {
    SelectPicker,
    FormInput,
  },
})
export default class CompanyBillingInfo extends ViewModel {
  @Prop() value!: Company

  @Prop({ default: false })
  public is_editing!: boolean

  public email_picker_options: Array<any> = []

  public get company() {
    return this.value || new Company()
  }

  public set company(val) {
    this.$emit('input', val)
  }

  public get masks() {
    return {
      percentageMask,
      currencyMask,
    }
  }

  public get invoice_grouping() {
    return {
      invoice_group_options,
      invoice_detail_options,
      InvoiceTemplates,
      InvoiceDeliveryMode,
      AgencyFeeModel,
      AgencyFeeMode,
    }
  }

  public get country_options() {
    return countryCodes
  }

  public get state_options() {
    return stateCodes
  }

  public addTag(newTag: string) {
    if (this.validateEmail(newTag)) {
      const tag = {
        name: newTag,
        value: newTag,
      }
      this.email_picker_options.push(tag)
      this.company.billing_info.billing_email.push(newTag)
    } else {
      WebMessage.error('Please enter a valid email.')
    }
  }

  public validateEmail(email: string) {
    const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return res.test(email.toLowerCase())
  }
}
