
import { Component, Vue, Watch } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader/PageHeader.vue'
import ViewModel from '@/models/ViewModel'
import Holding from '@/models/Holding'
import Widget from '@/components/Widget/Widget.vue'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import FormInput from '@/components/FormInput/FormInput.vue'
import OpportunityTable from '@/pages/Opportunity/components/OpportunityTable.vue'
import MediaPlanTable from '@/pages/Sales/MediaPlan/components/MediaPlanTable.vue'
import PrepPlanTable from '@/pages/PrePlan/components/PrepPlanTable.vue'
import Company from '@/models/Company'
import InvoiceTable from '@/pages/Invoice/Components/InvoiceSimpleTable.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import WebMessage from '@/models/WebMessage'
import ActivityTab from '@/pages/Opportunity/components/ActivityTab.vue'
import CompanyBasicDetails from './components/CompanyBasicDetails.vue'
import CompanyBillingInfo from './components/CompanyBillingInfo.vue'
import CompanyContactsTable from '../CompanyContact/components/CompanyContactsTable.vue'

@Component({
  components: {
    PageHeader,
    Widget,
    FooterNav,
    FormWizard,
    TabContent,
    FormInput,
    OpportunityTable,
    MediaPlanTable,
    PrepPlanTable,
    CompanyBasicDetails,
    InvoiceTable,
    IconAction,
    CompanyBillingInfo,
    CompanyContactsTable,
    ActivityTab,
  },
})
export default class CompanyDetails extends ViewModel {
  public company: Company | null = null

  public busy: boolean = true

  public open_side_form: boolean = false

  public tabIndex: number = 0

  public header_totals: any = {
    opportunities_amount: 0,
    media_plans_amount: 0,
    invoices_amount: 0,
  }

  public get _isEditing() {
    return !!this.$route.params.id
  }

  public loadCompany() {
    this.loading = true
    Company.find(this.$route.params.id)
      .then(response => {
        this.company = response
        this.loading = false
        this.busy = false
      })
      .catch(error => {
        this.loading = true
      })

    Company.getCompanyAmounts(this.$route.params.id).then(response => {
      this.header_totals = response.result
    })
  }

  public created() {
    this.loadCompany()
  }
}
