
import Widget from '@/components/Widget/Widget.vue'
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import DataTable from '@/components/DataTable/index.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import CompanyContact from '@/models/CompanyContact'
import Company from '@/models/Company'
import WebMessage from '@/models/WebMessage'
import company_contact_fields from '../company-contact-home-fields'
import CompanyContactForm from './CompanyContactForm.vue'

@Component({
  components: {
    DataTable,
    Widget,
    SearchInput,
    IconAction,
    CompanyContactForm,
  },
})
export default class CompanyContactsTable extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  @Prop()
  public company!: Company

  @Prop({ default: 'page' })
  public editLocation!: string

  @Prop({ default: false })
  public is_loading!: boolean

  public temp_company_contact: CompanyContact | any = null

  public open_side_form: boolean = false

  public query: string[] = []

  public fields: any = []

  public page_size: number = 25

  public sort_by: string = 'updated_at'

  public sort_desc: boolean = false

  public page: number = 1

  public records: number = 0

  public loading: boolean = false

  public saving_contact: boolean = false

  public ready: boolean = false

  public fieldFilters: any = {}

  @Watch('open_side_form')
  public onSideForm(val: boolean) {
    if (val) {
      document.body.classList.add('overflow-hidden')
      this.footerActions('hide')
      this.zIndexFix(99999)
    } else {
      document.body.classList.remove('overflow-hidden')
      this.footerActions('display')
      this.zIndexFix()
    }
  }

  public zIndexFix(val = 1) {
    let tab_content: HTMLElement | any = document.querySelector('div.tab-content')
    if (!tab_content) return
    tab_content.style.zIndex = val
  }

  public footerActions(n: string) {
    const footer: HTMLElement | any = document.querySelector('footer.footer-nav')
    if (!footer) return
    const actions: any = {
      hide: () => footer.classList.add('d-none'),
      display: () => footer.classList.remove('d-none'),
    }
    actions[n]()
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public mounted() {
    this.fields = company_contact_fields
    this.loadFilters()
  }

  public deleteContact(item: any) {
    item.delete().then(() => {
      this.refresh()
    })
  }

  public companyId() {
    if (this.company) return `company_id:${this.company.id}`
  }

  public loadData(context: any) {
    this.loading = true

    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    this.syncFilters()
    return CompanyContact.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters, this.companyId()],
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  // Store session filters in VUEX
  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'companies-contacts',
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  // Load filters from VUEX if present
  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('companies-contacts').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
      }
      this.ready = true
    })
  }

  // Clear VUEX filters state
  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'companies-contacts',
      data: null,
    })
  }

  // Refresh dataTable
  public refresh() {
    this.dataTable.refresh()
  }

  // Reset filters
  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.clearFilters()
    this.refresh()
  }

  public cancelEdition() {
    this.open_side_form = false
    this.temp_company_contact = null
  }

  public newContact() {
    this.open_side_form = true
    this.temp_company_contact = new CompanyContact()
  }

  public editContact(contact: any) {
    if (this.editLocation === 'page') {
      this.$router.push({ name: 'company-contact', params: { id: contact.id } })
    } else {
      this.open_side_form = true
      this.temp_company_contact = Object.assign(new CompanyContact(), { ...contact })
    }
  }

  public updateContact() {
    this.saving_contact = true

    this.temp_company_contact.save().then(res => {
      this.saving_contact = false
      this.refresh()
      setTimeout(() => {
        this.cancelEdition()
      }, 500)
    })
  }
}
