
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import DynamicRatePicker from '@/components/DynamicRatePicker/DynamicRatePicker.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import Company from '@/models/Company'
import ViewModel from '@/models/ViewModel'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { percentageMask, currencyMask } from '@/models/interface/Masks'

import { CompanyTypes, IndustryOptions, SubIndustryOptions } from '@/models/interface/Common'
import IconAction from '@/components/IconAction/IconAction.vue'

@Component({
  components: {
    CompanyPicker,
    DynamicRatePicker,
    FormInput,
    SelectPicker,
    IconAction,
  },
})
export default class CompanyBasicDetails extends ViewModel {
  @Prop() value!: Company

  @Prop({ default: false })
  public is_editing!: boolean

  public logo: any = null

  public logo_white: any = null

  public icon: any = null

  public logo_preview: any = null

  public logo_white_preview: any = null

  public icon_preview: any = null

  public get company() {
    return this.value || new Company()
  }

  public set company(val) {
    this.$emit('input', val)
  }

  public get company_type_options() {
    if (this.user.isSuperAdmin) {
      return CompanyTypes
    }
    return CompanyTypes.filter(
      (o: any) => o.value == 'advertiser' || o.value == 'station' || o.value == 'agency',
    )
  }

  public get company_industry_options() {
    return IndustryOptions
  }

  public get company_sub_industry_options() {
    if (!SubIndustryOptions[this.company.industry]) {
      return []
    }
    return SubIndustryOptions[this.company.industry]
  }

  public get masks() {
    return {
      percentageMask,
      currencyMask,
    }
  }
}
